.creditPage {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(223, 243, 255, 1),
    rgba(225, 238, 246, 0.67),
    rgb(255 255 255 / 0%)
  );
}
.creditMeterBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.creditPage .homeBg1 {
  width: 100%;
  height: 25%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}

.creditPage .homeBg2 {
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
.thakMessage {
  width: 90%;
  text-align: center;
  font-size: 20px !important;
  font-family: "Archivo" !important;
  font-weight: 700 !important;
  margin-bottom: 20px !important;
  color: #305ccd;
}
.creditPage .navBar {
  margin-bottom: 4px;
}
.creditPage .popInnerBox {
  background: #1565c014;
}
.creditPage .popInnerBox {
  width: 93%;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .thakMessage {
    width: 90%;
    margin-bottom: 60px !important;
    font-size: 30px !important;
  }
  .creditPage .navBar {
    margin-bottom: 40px;
  }
  .creditPage .popInnerBox {
    width: 500px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .thakMessage {
    width: 60%;
    margin-bottom: 20px !important;
  }
}
