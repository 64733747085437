.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  background: #202731;
}
.footerBottomBox {
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #ffffff12;
}
.footerBottomBox p {
  color: #fff;
  font-size: 8px !important;
}
.footerBg {
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.footermainSection {
  width: 100%;
  padding: 100px 0 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 45px;
  padding: 20px;
}
.footerClogo {
  width: 200px;
  margin-bottom: 20px;
  cursor: pointer;
}
.footerText {
  color: #fff;
  font-size: 14px !important;
}
.socialIcon {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.socialIcon img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.gapBox {
  width: 100%;
  margin-bottom: 15px;
}
.footerSubText {
  font-size: 16px !important;
  color: #fff;
  font-weight: 600 !important;
  margin-bottom: 15px;
}
.footerTextBox {
  width: 100%;
  z-index: 1;
}
.lFBox > div {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.lFBox > div img {
  margin-right: 10px;
}
.footer .featuresBgLine {
  z-index: 0;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .footer {
    height: 440px;
  }
  .footermainSection {
    flex-direction: row;
    padding: 100px 0 50px;
    gap: 3%;
  }
  .footerTextBox {
    width: 29%;
  }
  .footerBottomBox p {
    color: #fff;
    font-size: 14px !important;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .footermainSection {
    gap: 8%;
  }
  .footerTextBox {
    width: 24%;
  }
  .footerClogo {
    width: 300px;
  }
}
