* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  user-select: none;
  font-family: "Archivo" !important;
}

/* @font-face {
  font-family: "Archivo";
  src: url("./Assets/Fonts/Archivo_Black/ArchivoBlack-Regular.ttf")
    format("truetype");
} */

/* ScrollBar.css */
/* Horizontal Scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px; /* Height of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #b3b3b3; /* Color of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #949494; /* Color of the scrollbar thumb on hover */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.pointer {
  cursor: pointer;
}
