.appBtn {
  height: 39px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: #fff;
  font-size: 17px !important;
  font-weight: 700 !important;
  text-transform: none !important;
}

.FappBtn .appBtn:hover {
  background: #1565c0 !important;
}

.appBtn:hover {
  background: #2be158 !important;
}

.css-1e6y48t-MuiButtonBase-root-MuiButton-root:hover {
  background: #2be158 !important;
}

.css-qoybh1-MuiButtonBase-root-MuiButton-root:hover {
  background: #2be158 !important;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
}

/* Desktop view */
@media only screen and (min-width: 1460px) {
  .appBtn {
    height: 48px;
  }
}
