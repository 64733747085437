.homeContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.AppBox {
  width: 95%;
}
.heroSection {
  width: 100%;
  min-height: 1045px;
  position: relative;
  background: linear-gradient(
    to bottom,
    rgba(223, 243, 255, 1),
    rgba(225, 238, 246, 0.67),
    rgb(255 255 255 / 0%)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
}
.heroSection .homeBg1 {
  width: 100%;
  height: 25%;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.heroSection .homeBg2 {
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0px;
  left: 0px;
  z-index: -1;
}
.navBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 35px 0;
  position: relative;
  z-index: 2;
}
.Clogo {
  width: 180px;
  cursor: pointer;
}
.Clogo img {
  width: 100%;
  height: 100%;
}
.navLanguage {
  width: 140px;
  height: 40px;
  background: #020b21;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
}
.navLanguage p {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #fff;
  margin: 0 8px;
}
.navLanguage .LanDropIcon {
  transition: all 0.5s;
}
.LanDrop {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.5s;
  overflow: hidden;
  position: absolute;
  top: 55px;
  left: 0px;
  border-radius: 7px;
  overflow: hidden;
}
.LanDrop > div {
  display: flex;
  align-items: center;
  padding: 0 16px;
  width: 100%;
  min-height: 30px;
  background: #020b21;
  cursor: pointer;
  transition: all 0.5s;
  border-top: 0.2px solid #80808059;
}
.LanDrop > div p {
  font-size: 17px !important;
  font-weight: 700 !important;
  color: #fff;
}
.LanDrop > div :hover {
  background: #020b21af;
}
.homeMainBox {
  margin-top: 17px;
  display: flex;
  flex-direction: column;
}
.homeMainSubBox {
  width: 100%;
}
.applyInfoBox {
  display: flex;
  flex-direction: column;
}
.applyInfoBox button {
  box-shadow: rgb(0 0 0 / 15%) 5px 8px 4px;
}
.homeBoldText {
  font-size: 19px !important;
  font-weight: 900 !important;
  color: #133139;
}
.homeSubText {
  font-size: 12px !important;
  color: #262626;
}
.BusinessCardBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}
.businessCard {
  width: 30.3%;
  /* height: 142px; */
  border-radius: 18px;
  background: #ffffffa8;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 15px 9px;
}
.businessCard p {
  font-size: 14px !important;
  color: #5a5a5a;
  text-align: center;
  margin-top: 6px !important;
}
.businessCard span {
  font-size: 14px !important;
  color: #5a5a5a;
  font-weight: 600 !important;
}
.applyInputBox {
  border-radius: 18px;
  background: #fdfdfd2e;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  padding: 13px;
  position: relative;
  padding-bottom: 5px;
  margin-top: 40px;
}
.applyHeaderText {
  font-size: 19px !important;
  margin-bottom: 16px !important;
  font-weight: 800 !important;
}
.applyHeaderText span {
  color: #305ccd;
  font-size: 19px !important;
}
.input2Box {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 20px;
}
.inputBox {
  width: 100%;
  height: 40px;
  margin-bottom: 14px;
  border: 1px solid #b1b1b1;
  border-radius: 5px;
  position: relative;
}
.inputBox img {
  position: absolute;
  top: 12px;
  right: 20px;
}
.inputBox input {
  width: 100%;
  height: 100%;
  border: none;
  padding: 16px;
  border-radius: 5px;
}
.inputBox input:focus {
  outline: none;
}
.input2Box .inputBox {
  width: 50%;
  margin-bottom: 0px;
}
.otpBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  border: none;
}
.otpBox input {
  width: 33px;
  height: 33px;
  border-radius: 5px;
  border: 1px solid #b1b1b1;
  padding-left: 8px;
}
.check {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
}
.check p {
  font-size: 16px !important;
  color: #2a2a2a;
  margin-left: 16px !important;
}
.check input {
  cursor: pointer;
}
.findtText {
  width: 100%;
  font-size: 20px !important;
  color: #133139;
  position: absolute;
  bottom: -31px;
  left: 0px;
  text-align: center;
}
.findtText span {
  font-weight: 600 !important;
}
.findtText samp {
  font-weight: 600 !important;
  color: #305ccd;
  text-decoration: underline;
  cursor: pointer;
}
.sendOtpBtn {
  width: 78px;
  height: 25px;
  border-radius: 5px;
  background: #305ccd;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 6px;
  right: 17px;
}
.sendOtpBtn p {
  font-size: 12px;
  color: #fff;
}

.TrustSection {
  padding: 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.sectionBlueLabel {
  font-size: 15px !important;
  font-weight: 800 !important;
  text-align: center;
  color: #305ccd;
}
.sectionBoldLabel {
  width: 100%;
  font-size: 19px !important;
  font-weight: 900 !important;
  text-align: center;
}
.trustCardBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;
  margin-top: 24px;
}
.trustCard {
  width: 100%;
  height: 188px;
  padding: 20px;
  border-radius: 40px 40px 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.trustCard p {
  font-size: 22px !important;
  font-weight: 900 !important;
}
.trustCard span {
  font-size: 19px !important;
  font-weight: 800 !important;
  color: #121212;
  margin: 7px 0;
}
.trustCard samp {
  font-size: 15px !important;
  font-weight: 600;
}

.featuresBox {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.featureInnerBox {
  width: 100%;
  height: 729px;
  position: relative;
  border-radius: 15px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  overflow: hidden;
}
.featuresBgLine {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 200px;
  left: 0px;
  z-index: -1;
}
.featuresBgImg {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 41%;
  height: 30%;
}
.loanTextBox {
  width: 100%;
  height: 80%;
  position: absolute;
  top: 15px;
  left: 0px;
  padding: 0 16px;
  /* border: 1px solid; */
}
.loanTextBox .sectionBlueLabel {
  text-align: start;
  margin-bottom: 10px !important;
}
.loanTextBox .sectionBoldLabel {
  text-align: start;
  width: 66%;
}
.loanTextBox .homeSubText {
  margin: 10px 0;
}
.tikTextBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-bottom: 16px; */
}
.tikTextBox img {
  width: 24px;
  height: 13px;
  margin: 0 16px;
}

.calculatorSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  position: relative;
}
.calculatorSection .sectionBoldLabel {
  width: 100%;
}
.calculatorBox {
  width: 100%;
  background: #305ccd;
  border-radius: 30px;
  margin-top: 20px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}
.calculatorBox .featuresBgLine {
  z-index: 0;
}
.calculatorBox .controller {
  width: 100%;
}
.calculatorBox .metter {
  width: 100%;
  border-radius: 21px;
  border: #ffffff21 1px solid;
  box-shadow: rgb(17 41 67 / 20%) 0px 8px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  padding-bottom: 20px;
  position: relative;
  overflow: hidden;
}
.metterBg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  z-index: -1;
}
.yearsBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-bottom: 35px;
}
.calHeaderText {
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #fff;
}
.yearsBox .yearsBoxItem {
  width: 110px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 5px;
}
.yearsBox .yearsBoxItem p {
  color: #000000;
}
.yearsBoxItemActive {
  background: #305ccd !important;
  border: 1px solid #ffffff94;
}
.yearsBoxItemActive p {
  color: #fff !important;
}
.controller .subText {
  font-size: 14px !important;
  color: #fff;
  margin: 9px 0;
}
.lineBarBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 16px;
}
.valueBox {
  width: 170px;
  height: 28px;
  border: 1px solid #ffffffd2;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.valueBox input {
  width: 100%;
  height: 100%;
  border: none;
  background: rgba(255, 0, 0, 0);
  padding-left: 10px;
  color: #fff;
}
.valueBox input:focus {
  outline: none;
}
.valueBox p {
  position: absolute;
  top: 3px;
  right: 10px;
}
.lineBarLabel {
  font-size: 14px !important;
  color: #fff;
}
.dateBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 20px 0;
}
.dateBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.dateInputBox {
  width: 40%;
  position: relative;
}
.dropInputBox {
  width: 100%;
  height: 37px;
  display: flex;
  justify-content: start;
  align-items: center;
  border: 1px solid #ffffffb6;
  border-radius: 5px;
  margin-top: 10px;
  cursor: pointer;
}
.DropBox {
  width: 100%;
  max-height: 180px;
  flex-direction: column;
  overflow: scroll;
  border-radius: 7px;
  border: 1px solid rgba(128, 128, 128, 0.61);
  position: absolute;
  top: 105px;
  z-index: 9;
}
.dropItem {
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.61);
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: #fff;
  cursor: pointer;
}
.lineBarBox .css-188mx6n-MuiSlider-root {
  height: 5px;
}
.lineBarBox .css-7o8aqz-MuiSlider-rail {
  color: #fff;
  opacity: 1;
}

.lineBarBox .css-ttgsjq-MuiSlider-track {
  color: #2be158 !important;
}
.lineBarBox .css-1as9v07-MuiSlider-thumb {
  color: #2be158 !important;
}
.greenCircul {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.CircularProgressbar {
  width: 50% !important;
}

.emiIndecatorBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
.emiIndecatorItem {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.emiIndecatorItem p {
  color: #fff;
}
.emiIndecatorItem > div {
  width: 15px;
  height: 15px;
  margin-right: 8px;
}

.metter .CircularProgressbar .CircularProgressbar-trail {
  stroke: #ffffff;
}
.metter .CircularProgressbar .CircularProgressbar-path {
  stroke: #2be158;
}
.greenCircul .CircularProgressbar-text {
  font-size: 16px !important;
  fill: #fff !important;
  position: absolute;
  top: 37%;
  left: 37%;
  text-align: center;
}
.totalAmoutLabel {
  font-size: 25px !important;
  color: #fff;
  font-weight: 600;
  text-align: center;
}
.metterTextRow {
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}
.borderLine {
  width: 100%;
  border-bottom: 1px solid #ffffff36;
  margin-top: 10px;
}
.trowMargin {
  margin-bottom: 7%;
}

.chooseUsBox {
  padding: 50px 0;
}
.chooseCardBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  background: #ebebeb36;
  justify-content: space-between;
  margin-top: 15px;
  padding: 40px 20px 40px 20px;
  border-radius: 20px;
}
.chooseUsBox .sectionBlueLabel {
  text-align: start;
}
.chooseUsBox .sectionBoldLabel {
  text-align: start;
}
.chooseCard {
  width: 48%;
  background: #fff;
  border-radius: 19px;
  margin-bottom: 20px;
  padding: 20px;
}
.chooseCard p {
  font-size: 18px !important;
  font-weight: 700 !important;
}
.chooseCard span {
  font-size: 13px !important;
  font-weight: 700 !important;
}
.eligibility {
  display: flex;
  flex-direction: column;
  gap: 45px;
  padding: 0px 0;
}
.eligibilityImg {
  width: 100%;
}
.eligibilityTextBox {
  width: 100%;
}
.eligibilityImg img {
  width: 100%;
}
.eligibilityTextBox {
  display: flex;
  flex-direction: column;
}
.eligibility .sectionBoldLabel {
  width: 100%;
  text-align: start;
}
.appSubHeaderText {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #262626;
}
.eligibility .appSubHeaderText {
  margin: 10px 0 !important;
}
.rowBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
}
.rowBox p {
  font-size: 15px !important;
  margin-left: 10px !important;
}

.appSubHeaderText span {
  font-size: 13px !important;
  color: #262626;
  font-weight: 400 !important;
}
.marginText {
  margin-bottom: 5px !important;
}
.questionSection {
  display: flex;
  flex-direction: column-reverse;
  gap: 45px;
  margin-top: 50px;
}
.questionImgBox {
  width: 100%;
}
.questionImgBox img {
  width: 100%;
}
.questionBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

.questionSection .sectionBoldLabel {
  text-align: start;
  width: 100%;
  margin-bottom: 30px !important;
}
.questionSection .sectionBlueLabel {
  text-align: start;
}
.colapsBox {
  width: 100%;
  height: 85px;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.colapsBoxActive {
  height: 170px;
}
.colapsDrop {
  width: 100%;
  min-height: 85px;
  display: flex;
  align-items: center;
  background-color: #ffffff;

  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  padding: 0 20px;
  border-bottom: 1px solid #000;
}
.colapsDropActive {
  border-radius: 8px;
  background-color: #f7f7f7;
  border: none;
}
.colapsDrop p {
  width: 90%;
  font-size: 18px !important;
  color: #464646;
  font-weight: 600 !important;
}
.colapsDrop img {
  position: absolute;
  top: 39px;
  right: 25px;
  transition: all 0.5s;
}
.colapsTextArea p {
  color: #323232;
  font-size: 13px !important;
  line-height: 18.5px;
}
.colapsDrop:hover p {
  color: #021929;
}

.colapsTextArea {
  padding: 15px 30px;
}

.AppProcessSection {
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.AppProcessSection .sectionBoldLabel {
  width: 100%;
}
.AppProcessSection .homeSubText {
  width: 90%;
  text-align: center;
}
.stepBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 25px;
}
.stepItem {
  width: 100%;
  height: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.stepItem .stepBg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  z-index: -1;
}
.stepItem p {
  font-size: 17px !important;
  font-weight: 600 !important;
  color: #305ccd;
  text-align: center;
}
.stepItem span {
  width: 54%;
  font-size: 24px !important;
  font-weight: 600 !important;
  text-align: center;
  margin: 10px 0 !important;
}
.stepItem samp {
  width: 53%;
  font-size: 14px !important;
  text-align: center;
}
.StoriesSection {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.storiesBg {
  position: absolute;
  bottom: 1814px;
  left: 0px;
  width: 100%;
  height: 848px;
  z-index: -1;
}
.storiesCard {
  width: 100%;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  position: relative;
}
.bgBox {
  position: absolute;
  top: 10px;
  left: 0px;
  width: 100%;
  height: 90%;
  background: #f9f9f9;
  z-index: -1;
  border-radius: 12px;
  transform: skew(0deg, 349deg);
}
.croselBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-top: 8px;
}
.storiesCard .starBox {
  width: 100%;
  display: flex;
  justify-content: end;
}
.storiesCard .starBox img {
  width: 40%;
}
.storiesCard .cote {
  width: 100%;
  display: flex;
  justify-content: start;
  margin: 15px 0;
}
.storiesCard p {
  font-weight: 500 !important;
  font-size: 12px !important;
  margin-bottom: 17px !important;
  line-height: 20px;
}
.storiesCard span {
  font-weight: 700 !important;
  font-size: 13px !important;
  margin-bottom: 3px !important;
}
.storiesCard samp {
  font-weight: 400 !important;
  font-size: 14px !important;
}
.topBtnBox {
  width: 100%;
  display: flex;
  justify-content: end;
}
.topBtnBox Button {
  width: 100% !important;
}
.loanTextBox .appBtn {
  width: 55% !important;
  position: relative;
  bottom: -82px;
}
.popBackDrop {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0px;
  left: 0px;
  background: rgb(67 67 67 / 58%);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popInnerBox {
  width: 500px;
  background-color: #fff;
  border-radius: 13px;
  padding: 20px;
}
.popHeader {
  width: 100%;
  text-align: center;
  font-size: 20px !important;
  font-weight: 600 !important;
}
.popSubText {
  width: 100%;
  text-align: center;
  font-size: 12px !important;
  color: #b1b1b1;
  margin-top: 10px !important;
}
.scoreMeter {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.TimeDLabel {
  font-size: 20px !important;
  font-weight: 800 !important;
  color: #fff;
}
.calculateBtnBox {
  width: 100%;
  display: flex;
  justify-content: end;
}

/* Tabe view */
@media only screen and (min-width: 768px) {
  .Clogo {
    width: 293px;
    height: 54px;
  }
  .AppBox {
    width: 90%;
  }
  .inputBox {
    height: 60px;
  }
  .inputBox img {
    top: 20px;
  }
  .navLanguage {
    height: 54px;
  }
  .homeBoldText {
    font-size: 39px !important;
  }
  .homeSubText {
    font-size: 13px !important;
  }
  .topBtnBox Button {
    width: 222px !important;
  }
  .applyHeaderText {
    font-size: 24px !important;
  }
  .applyHeaderText span {
    font-size: 24px !important;
  }
  .applyInputBox {
    padding: 24px;
  }
  .heroSection {
    min-height: 800px;
  }
  .findtText {
    bottom: -50px;
  }
  .TrustSection {
    padding: 100px 0;
  }
  .sectionBoldLabel {
    width: 50%;
    font-size: 26px !important;
  }
  .trustCardBox {
    flex-direction: row;
  }
  .trustCard {
    width: 25%;
    height: 188px;
    padding: 20px;
  }
  .topMargin {
    margin-top: 35px;
  }
  .featureInnerBox {
    width: 100%;
    height: 598px;
  }
  .featuresBgImg {
    bottom: 0px;
    right: 0px;
    width: 41%;
    height: 70%;
  }
  .calculatorBox {
    flex-direction: row;
    padding: 24px;
  }
  .calculatorBox .controller {
    width: 50%;
  }
  .calculatorBox .metter {
    width: 50%;
  }
  .dropInputBox {
    height: 50px;
  }
  .featuresBox {
    margin-bottom: 100px;
  }
  .calculatorSection {
    margin-bottom: 50px;
  }
  .stepBox {
    flex-direction: row;
  }
  .stepItem {
    width: 30%;
    height: 190px;
  }
  .stepItem p {
    font-size: 12px !important;
  }
  .stepItem span {
    font-size: 15px !important;
  }
  .stepItem samp {
    font-size: 12px !important;
  }
  .chooseCardBox {
    margin-top: 50px;
  }
  .chooseCard {
    width: 23%;
  }
  .eligibility {
    flex-direction: row;
  }
  .eligibilityImg {
    width: 45%;
  }
  .eligibilityTextBox {
    width: 55%;
  }
  .eligibility {
    padding: 65px 0;
  }
  .croselBox {
    flex-direction: row;
    margin-top: 65px;
  }
  .storiesCard {
    width: 32%;
    height: 230px;
  }
  .questionSection {
    margin-top: 20px;
    flex-direction: row;
    padding: 65px 0;
  }
  .questionImgBox {
    width: 40%;
  }

  .questionBox {
    width: 60%;
    margin-bottom: 0px;
  }
  .questionSection .sectionBoldLabel {
    margin-bottom: 0px !important;
  }
  .storiesBg {
    bottom: 1011px;
  }
  .sendOtpBtn {
    top: 17px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1000px) {
  .AppBox {
    width: 70%;
  }
  .heroSection {
    height: 100vh;
  }
  .homeMainBox {
    flex-direction: row;
  }
  .applyInfoBox {
    margin-right: 40px;
  }
  .applyInputBox {
    margin-top: 0;
    margin-left: 40px;
  }
  .homeMainSubBox {
    width: 50%;
  }
  .topBtnBox {
    justify-content: start;
  }
  .inputBox {
    height: 40px;
  }
  .inputBox img {
    top: 12px;
  }
  .sendOtpBtn {
    width: 82px;
    height: 27px;
    top: 6px;
  }
  .findtText {
    text-align: start;
  }

  .featuresBox {
    margin: 100px 0;
  }
  .featuresBgImg {
    height: 78%;
  }
  .calculatorBox {
    gap: 40px;
  }
  .calculatorBox .controller {
    width: 60%;
  }
  .calculatorBox .metter {
    width: 40%;
  }
  .metterTextRow {
    width: 80%;
  }
  .dropInputBox {
    height: 60px;
  }
  .stepItem {
    width: 30%;
    max-width: 293px;
    min-width: 290px;
    height: 250px;
  }
  .stepItem span {
    font-size: 20px !important;
  }

  .storiesCard {
    min-width: 288px;
    height: 300px;
  }
  .storiesCard p {
    font-size: 17px !important;
  }
  .storiesCard span {
    font-weight: 700 !important;
    font-size: 15px !important;
  }
  .storiesCard samp {
    font-size: 16px !important;
  }
  .storiesCard .starBox img {
    width: unset;
  }
  .loanTextBox .appBtn {
    position: relative;
    bottom: 0;
    width: unset !important;
    margin-top: 20px;
  }
  .storiesBg {
    height: 690px;
    bottom: 1095px;
  }
  .loanTextBox {
    width: 60%;
    height: 80%;
    top: 50px;
    left: 50px;
  }
  .AppProcessSection .homeSubText {
    width: 60%;
  }
  .questionSection {
    margin-top: 100px;
  }
  .featureInnerBox {
    height: 717px;
  }
}

/* Desktop view */
@media only screen and (min-width: 1460px) {
  .homeBoldText {
    font-size: 50px !important;
  }
  .homeMainBox {
    margin-top: 50px;
  }
  .inputBox {
    height: 48px;
    margin-bottom: 20px;
  }
  .inputBox img {
    top: 15px;
  }
  .sendOtpBtn {
    top: 13px;
  }
  .businessCard p {
    font-size: 18px !important;
  }
  .businessCard span {
    font-size: 18px !important;
  }
}
